@import "~normalize.css/normalize.css";
@import "partials/_variables.scss";
@import "partials/_accordion.scss";
@import "partials/_slider.scss";
@import "partials/_columns.scss";
@import "partials/_buttons.scss";
@import "partials/_forms.scss";
@import "partials/_messages.scss";
@import "partials/_breakpoints.scss";

@import "partials/_cart.scss";

// Font
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

$max-width: 1440px;
$min-width: 320px;

// Global Styles
*,
*:before,
*:after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body,
html {
    font-family: $font;
    position: relative;
    text-align: left;
    -webkit-overflow-scrolling: touch;
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

hr {
    margin: 2em 0;
}

h1 {
    font-size: 2rem;
    margin: 0.5em 0;

    @include xs {
        font-size: 1.75rem;
    }
}

h2 {
    font-size: 1.75rem;
    margin: 0.5em 0;
}

h3 {
    font-size: 1.5rem;
    margin: 0.5em 0;
}

h4 {
    font-size: 1.25rem;
    margin: 0.5em 0;
}

h5 {
    font-size: 1.125rem;
    margin: 0.5em 0;
}

h6 {
    font-size: 1rem;
    margin: 0.5em 0;
}

a {
    color: inherit;
    font-weight: 500;
}

p {
    font-size: 1rem;
    line-height: 24px;
    margin-bottom: 1.125rem;
}

ul {
    margin: 1.125em 0;
    padding-left: 1em;

    li {
        font-size: 1rem;
        line-height: 24px;
        font-weight: 400;
        margin-bottom: 0.5rem;

        img {
            display: inline-block;
            margin: 0 0.5em 0 0;
        }
    }
}

ol {
    margin: 1.125em 0;
    padding-left: 1em;

    li {
        font-size: 1rem;
        line-height: 24px;
        font-weight: 300;
        margin-bottom: 0.25rem;

        img {
            display: inline-block;
            margin: 0 0.5em 0 0;
        }
    }
}

blockquote {
    background: rgba(0, 0, 0, 0.03);
    margin: 0 0 1rem;
    padding: 1.25rem;
    border-left: 0.75rem solid $primary;
}

img.fr-bordered {
    border: 5px solid $primary;
}

table {
    &.fr-alternate-rows {
        tr {
            &:nth-child(odd) {
                background: $light-grey;
            }
        }
    }

    tr {
        th {
            background: $primary;
            padding: 0.5rem 1rem;
            text-align: left;
            font-weight: 300;
            font-size: 1.125rem;
            color: $white;
        }

        td {
            padding: 0.5rem 1rem;
            font-weight: 300;
            font-size: 1rem;
            line-height: 24px;

            &.fr-highlighted {
                background: $secondary;
                color: $white;
            }

            &.amount {
                position: relative;
                padding-left: 2rem;
                text-align: right;

                &::before {
                    content: "$";
                    position: absolute;
                    left: 1rem;
                }
            }

            .fr-file {
                display: block;
                width: 2.5rem;
                height: 2.5rem;
                padding: 0;
                border-radius: 3px;
                border: 0 none;
                text-indent: -999rem;

                &::before {
                    left: 50%;
                    text-indent: initial;
                    transform: translate(-50%, -50%);
                }

                &::after {
                    content: none;
                }

                &:hover {
                    background: $primary;
                }
            }
        }
    }
}

// Error pages
.text-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    padding: 0.5em;
}

.container {
    padding: 20px;
    max-width: $max-width;
    min-width: $min-width;
    margin: 0 auto;
    .row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 0 auto;
    }
}

nav {
    &.breadcrumbs {
        text-align: left;
        ul {
            display: inline-block;
            li {
                display: inline-block;
                &:not(:last-child)::after {
                    content: ">";
                    display: inline-block;
                }
            }
        }
    }
}
body > header {
    nav {
        ul {
            li {
                list-style: none;
                display: inline-block;
            }
        }
        // &.user {
        //     .cart {
        //         box-shadow: 5px 5px 5px rgba($off-black, 0.2);
        //         .cart-items {
        //             padding-top: 10px!important;
        //             padding-bottom: 0!important;
        //             .cart-item {
        //                 padding: 0 0;
        //                 .item-details {
        //                     flex: 1 1 auto;
        //                 }
        //                 .item-qty {
        //                     flex: 0 0 auto;
        //                 }
        //                 .item-price {
        //                     flex: 1 1 auto;
        //                     text-align: right;
        //                 }
        //                 .item-remove {
        //                     flex: 0 0 auto;
        //                     margin-right: -10px;
        //                     margin-left: 10px;
        //                 }
        //             }
        //         }
        //         .totals {
        //             font-weight: 600;
        //             padding:  5px 10px 5px 5px;
        //             border-top: 1px dotted $off-black;
        //         }
        //     }
        // }
    }
}

main {
    &.account,
    &.checkout {
        ul.sections {
            justify-content: center;
            li {
                display: block;
                padding: 0 10px;
                a {
                    display: block;
                    border: 1px solid $black;
                    border-radius: 5px;
                    padding: 10px 20px;
                }
                &.current {
                    a {
                        background: $black;
                        color: $white;
                    }
                }
            }
        }
        form {
            max-width: 600px;
            flex: 1 0 auto;
        }
        &.address,
        &.cart {
            form {
                max-width: 980px;
                flex: 1 0 auto;
            }
        }
    }
    &.checkout {
        &.cart {
            .cart-items {
                & > li.cart-item {
                    padding: 5px 10px;
                    input {
                        margin: 0;
                    }
                    .item-image {
                        width: 40px;
                        height: 40px;
                        flex: 0 0 auto;
                    }
                    .item-details {
                        text-align: left;
                        justify-self: flex-start !important;
                        flex: 1 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                    }
                    .item-qty {
                        flex: 0 0 auto;
                        padding: 0 10px;
                        width: calc((100% - 200px) / 2);
                        input {
                            display: inline-block;
                            width: 8rem;
                        }
                    }
                    .item-price {
                        flex: 0 0 auto;
                        text-align: right;
                        max-width: 8rem;
                        padding: 0 10px;
                    }
                    .item-remove {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .totals {
                font-weight: 600;
                padding: 0 20px;
                span {
                    display: inline-block;
                    min-width: 8rem;
                }
            }
        }
        &.shipping,
        &.payment {
            & > .container > .row {
                justify-content: center;
                align-items: flex-start;
            }
            form {
                margin: 0;
                fieldset {
                    &.shipping-methods {
                        padding: 20px;
                        label {
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            input {
                                margin-right: 1em;
                            }
                            span {
                                font-weight: 600;
                                display: inline-block;
                                margin-left: 1em;
                            }
                        }
                    }
                    &.buttons {
                        display: flex;
                        justify-content: center;
                        button,
                        .button {
                            margin: 2em 10px 0;
                        }
                    }
                }
                .payment-method {
                    margin-top: 20px;
                    padding-top: 20px;
                }
                .message {
                    padding: 20px 10px;
                    text-align: left;
                    ul {
                        margin: 1em 2em;
                    }
                }
            }
            .totals {
                border-radius: 5px;
                padding: 40px 40px;
                max-width: 330px;
                margin: 0 10px;
                flex: 1 0 auto;
                text-align: left;
                ul {
                    margin: 1em 0;
                    li {
                        list-style: none;
                        display: flex;
                        justify-content: space-between;
                        font-weight: 600;
                        padding: 0.5em 5px;
                        &.total {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

main {
    &.products {
        ul.row {
            justify-content: flex-start;
            li.product {
                list-style: none;
                border: 1px solid $black;
                overflow: hidden;
                width: calc(25% - 20px);
                margin: 10px;
                a {
                    &.image {
                        display: block;
                        width: 100%;
                        padding-top: 100%;
                        background-size: contain;
                    }
                }
                div {
                    text-align: left;
                    padding: 20px 40px;
                }
            }
        }
        &.single {
        }
    }
}

// Flatpickr
.flatpickr-wrapper {
    @apply w-full;
}

.flatpickr-calendar.open {
    z-index: 1000 !important;
    filter: none !important;
}

.flatpickr-months {
    @apply mb-2 text-gray-800;
}

.flatpickr-next-month,
.flatpickr-prev-month {
    @apply text-gray-800;

    svg {
        fill: currentColor !important;
    }
}

.flatpickr-current-month span.cur-month:hover,
.numInputWrapper:hover {
    @apply bg-transparent;
}

.flatpickr-day {
    @apply border-0;

    &.today {
        @apply font-semibold;

        &:hover {
            color: inherit;
            @apply bg-gray-400;
        }
    }

    &:hover {
        @apply bg-gray-400;
    }

    &.selected {
        @apply bg-blue-600 text-white;

        &:hover,
        &.today:hover {
            @apply bg-blue-400 text-white;
        }
    }
}

.flatpickr-time {
    border: none !important;

    input,
    .flatpickr-am-pm,
    .flatpickr-time {
        background: none !important;
    }
}

.flatpickr-time input.flatpickr-hour,
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
    @apply font-semibold;

    &::selection {
        background: none;
    }
}

// Amotiv

// General

.container {
    padding: 0 40px;
    max-width: $max-width;
    min-width: $min-width;
    margin: 0 auto;

    @include md {
        padding: 0 20px;
    }
}

.row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    @include md {
        flex-direction: column;
        align-items: flex-start;
    }

    &.reverse {
        @include xs {
            flex-direction: column-reverse;
        }

        .column {
            &:first-child {
                margin-top: 1em;
            }
        }
    }

    .column {
        width: calc(50% - 2em);

        @include md {
            width: 100%;
        }

        &.right {
            @include md {
                margin-top: 2em;
            }
        }
    }
}

.item-block {
    position: relative;
    padding: 2em;
    background: $background;

    @include xs {
        padding: 1.5em;
    }

    .item-block-title {
        display: block;
        text-align: left;
        font-size: 1.75rem;
        font-weight: 600;
        color: $secondary;
        width: 100%;
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
        border-bottom: 3px solid #cccccc;
    }

    p {
        color: $black;
    }
}

.read-more {
    width: fit-content;
    color: $primary;
    font-weight: 600;
    font-size: 1rem;
    text-decoration: none;
    padding-bottom: 0.25rem;
    position: relative;

    &:after {
        content: "";
        background-image: url("/img/double-arrows.svg");
        background-size: 11.82px 10.38px;
        display: inline-block;
        margin-left: 0.5em;
        width: 11.82px;
        height: 10.38px;
        z-index: 2;
    }

    &:hover {
        border-bottom: 1px solid $primary;
    }
}

#two-columns-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;

    @include lg {
        gap: 1.5em;
    }

    @include md {
        grid-template-columns: repeat(1, 1fr);
    }

    .column.left {
        overflow: hidden;
    }
}

.three-columns-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin: 2em auto;

    @include xs {
        grid-template-columns: repeat(1, 1fr);
    }
}

// Header

header {
    background: $white;
    box-shadow: 0 5px 13px rgba(0, 0, 0, 0.125382);
    z-index: 101;
    position: fixed;
    width: 100%;
    top: 0;

    .row {
        padding: 1em 0;
        align-items: center;
        flex-direction: row !important;
    }

    .logo {
        display: block;
        width: auto;
        height: 70px;

        @include md {
            height: 50px;
        }
    }

    .desktop-menu {
        nav {
            display: flex;
            align-items: center;

            ul {
                li {
                    position: relative;
                    margin: 0 1em;

                    &:hover {
                        ul {
                            transform: translate(0, 0);
                            visibility: visible;
                            opacity: 1;
                        }
                    }

                    &:first-child {
                        margin-left: 0;
                    }

                    a,
                    span {
                        padding: 0.625rem 0;
                        color: $secondary;
                        font-weight: 500;
                        font-size: 1.125rem;
                        text-decoration: none;
                        text-transform: uppercase;

                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            bottom: -5px;
                            background: $primary;
                            width: 0;
                            height: 3px;
                            transition: all 0.3s ease;
                        }

                        &:hover,
                        &.active {
                            color: $primary;

                            &:after {
                                width: 100%;
                            }
                        }
                    }

                    ul {
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        top: calc(100% + 0.5rem);
                        left: 0;
                        background: $white;
                        width: 11rem;
                        padding: 0.5rem 0;
                        box-shadow: 0 3px 5px rgba(38, 42, 47, 0.15);
                        transform: translateY(1rem);
                        transition: all 0.3s ease;
                        visibility: hidden;
                        opacity: 0;
                        z-index: 1;

                        li {
                            margin: 0.25rem 0;

                            a {
                                display: block;
                                font-size: 1rem;
                                padding: 0.25rem 1.5rem;
                                text-align: left;
                                text-transform: none;

                                &:before {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    background: $primary;
                                    width: 3px;
                                    height: 0;
                                    transition: all 0.3s ease;
                                }

                                &:after {
                                    content: none;
                                }

                                &:hover,
                                &.active {
                                    &:before {
                                        height: 100%;
                                    }
                                }
                            }

                            ul {
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

// -- Mobile menu

.desktop-menu {
    display: block;

    @include lg {
        display: none;
    }
}

.mobile-menu {
    display: none;

    @include lg {
        position: relative;
        display: block;
        z-index: 101;
    }

    .row {
        align-items: center;

        @include md {
            flex-direction: row;
        }
    }

    .logo-link {
        z-index: 1000;
    }

    .logo {
        width: 12em;
    }

    .mobile-menu-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1.5em;

        .column.right {
            display: flex;
            flex-direction: row;
        }
    }
}

.burger-menu {
    display: none;

    @include lg {
        width: 30px;
        height: 30px;
        display: block;
        padding: 0;
        border-bottom: 0 none;
        position: relative;
        right: 0;
        cursor: pointer;
        z-index: 101;
        transition: opacity 0.25s ease;

        &:hover {
            opacity: 0.7;
        }

        &.active {
            .top {
                transform: translateY(11px) translateX(0) rotate(45deg);
            }

            .middle {
                opacity: 0;
            }

            .bottom {
                transform: translateY(-9px) translateX(0) rotate(-45deg);
            }
        }

        span {
            background: $primary;
            border: none;
            height: 2px;
            width: 100%;
            position: absolute;
            top: 4px;
            left: 0;
            transition: all 0.35s ease;
            cursor: pointer;
            z-index: 101;

            &:nth-of-type(2) {
                top: 14px;
            }

            &:nth-of-type(3) {
                top: 24px;
            }
        }
    }
}

.overlay {
    width: 100%;
    height: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    z-index: 100;

    &.open {
        opacity: 1;
        visibility: visible;
        height: 100%;

        li {
            animation: fadeInRight 0.5s ease forwards;
            animation-delay: 0.35s;

            &:nth-of-type(2) {
                animation-delay: 0.4s;
            }

            &:nth-of-type(3) {
                animation-delay: 0.45s;
            }

            &:nth-of-type(4) {
                animation-delay: 0.5s;
            }

            &:nth-of-type(5) {
                animation-delay: 0.55s;
            }

            &:nth-of-type(6) {
                animation-delay: 0.6s;
            }

            &:nth-of-type(7) {
                animation-delay: 0.65s;
            }

            &:nth-of-type(8) {
                animation-delay: 0.7s;
            }

            &:nth-of-type(9) {
                animation-delay: 0.75s;
            }

            &:nth-of-type(10) {
                animation-delay: 0.8s;
            }

            &:nth-of-type(11) {
                animation-delay: 0.85s;
            }

            &:nth-of-type(12) {
                animation-delay: 0.9s;
            }
        }
    }

    @include md {
        opacity: 0;
        visibility: hidden;
    }
}

.overlay-menu {
    nav {
        display: block;
        position: fixed;
        top: 5.125em;
        left: 50%;
        right: 0;
        bottom: 0;
        transform: translate(-50%, 0);
        background: $secondary;
        width: 100%;
        height: 100%;
        padding: 1rem 0 7em 0;
        transition: all 0.3s ease;
        overflow-x: scroll;
        z-index: 2;

        ul {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0;
            padding-left: 0;

            li {
                text-align: left;
                margin-bottom: 0;
                padding: 0.15em 1.25em;

                a {
                    display: block;
                    width: 100%;
                    color: $white;
                    font-family: $font;
                    font-size: 1rem;
                    text-decoration: none;
                    padding: 0.25em 0.5em;

                    &:hover {
                        background: hsla(0, 0%, 100%, 0.15);
                        color: $white;
                    }
                }

                ul {
                    margin: 0 0 0.5em;

                    li {
                        ul {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }
}

// Footer

footer {
    border-top: 1px solid $white;

    .footer-top {
        background: $secondary;

        @include md {
            padding: 1.25em 0;
        }

        .container {
            padding-top: 1.5em;
            padding-bottom: 1.5em;

            @include md {
                padding-top: 0;
                padding-bottom: 0;
            }
        }

        .row {
            align-items: flex-end;

            @include md {
                flex-direction: row;
            }

            @include xs {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .footer-col-left {
            max-width: 500px;

            @include xs {
                flex-direction: column;
                max-width: fit-content;
            }

            .logo {
                height: 3.75em;

                @include md {
                    height: 3em;
                }
            }

            p {
                color: $white;
                font-size: 0.85rem;
                margin-bottom: 0;
            }

            .footer-menu {
                margin-top: 1em;

                @include md {
                    text-align: left;
                    padding-left: 0;
                    margin-bottom: 1em;
                }

                nav ul {
                    text-align: left;
                    margin: 0;
                    padding-left: 0;

                    @include md {
                        padding-left: 0;
                    }

                    li {
                        list-style-type: none;
                        margin-bottom: 0.5em;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        a {
                            color: $white;
                            font-size: 1rem;
                            text-decoration: none;

                            &:hover {
                                color: $tertiary;
                            }
                        }

                        ul {
                            display: none;
                        }
                    }
                }
            }
        }

        .footer-col-right {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            @include lg {
                align-items: flex-start;
            }

            @include md {
                flex-direction: column;
                align-items: flex-start;
                margin-top: 0;
            }
        }
    }

    .footer-bottom {
        background-color: $primary;

        .row {
            align-items: flex-end;

            @include md {
                align-items: flex-start;
            }
        }

        .container {
            padding-top: 1em;
            padding-bottom: 1em;

            @include xs {
                padding-top: 0.75em;
                padding-bottom: 0.75em;
            }
        }

        a {
            color: $white;
            font-weight: 400;
            text-decoration: none;
            font-size: 0.875rem;

            @include xs {
                font-size: 0.75rem;
            }
        }

        span {
            color: $white;
            font-size: 0.875rem;

            @include xs {
                display: block;
                margin-top: 0.25em;
                font-size: 0.75rem;
            }
        }
    }
}

// Announcement

#announcement {
    position: relative;
    display: block;
    width: 100%;
    padding: 0.5em 0;
    text-align: center;
    background: $secondary;
    transition: all 0.3s ease;

    .announcement-text {
        @extend .container;
        display: flex;
        align-items: center;
    }

    h3 {
        display: inline-block;
        color: $white;
        font-size: 1.125rem;
        margin-right: 0.5em;

        @include xs {
            font-size: 1rem;
            margin-right: 0;
        }
    }

    a {
        display: inline-block;
        color: $white;
        padding: 0.5em 0.5em;
        border: 1px solid $white;

        &:hover {
            background: $secondary;
            border: none;
        }

        @include xs {
            font-size: 0.875rem;
        }
    }
}

// Homepage

main {
    display: block;
    position: relative;
}

// Home Slider
.home-main-slider {
    height: 50vw;
    max-height: 700px;
    padding: 0;
    margin-bottom: 0 !important;

    @include md {
        height: 50vw;
    }

    @include xs {
        height: 95vw;
    }

    @media (max-width: 400px) {
        height: 135vw;
    }

    .slick-slide {
        height: 50vw;
        max-height: 700px;

        @include md {
            height: 50vw;
        }

        @include xs {
            height: 95vw;
        }

        @media (max-width: 400px) {
            height: 135vw;
        }

        &:before {
            background-color: transparent !important;
        }

        .slide-image {
            opacity: 1;

            @include xl {
                background-position: bottom;
            }

            @include xs {
                height: 100%;
                max-height: 95vw;
            }

            @media (max-width: 400px) {
                max-height: 135vw;
            }
        }

        figure {
            position: relative;
            height: 100%;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
        }
    }

    .slick-dots {
        position: relative;
        bottom: 4em;
        left: 50%;
        max-width: 100%;
        min-width: 1440px;
        margin: 0 auto;
        display: block;
        z-index: 3;
        transform: translate(-50%, -50%);

        @include xs {
            bottom: 0;
        }

        @media (max-width: 400px) {
            transform: translate(-35%, -50%);
        }
    }

    .show {
        height: 50vw;
        max-height: 700px;

        @include md {
            height: 50vw;
        }

        @include xs {
            height: 95vw;
        }

        @media (max-width: 400px) {
            height: 135vw;
        }
    }

    .show {
        max-width: 100%;
        padding: 0 !important;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .container {
        position: relative;
    }

    .caption-container {
        position: relative;
        max-width: 1440px;
        height: 100%;
        margin: 0 auto;
        display: block;
        z-index: 999;

        @include xs {
            width: 100%;
            height: 50%;
            top: 50vw;
            position: relative;
            max-width: 100%;
        }

        @include xxs {
            top: 55vw;
        }
    }

    .caption {
        position: absolute;
        background: transparent !important;
        color: $primary;
        text-align: left;
        z-index: 999;
        padding: 0 !important;
        top: 22em;
        left: 40px;
        transform: translate(0, -30%);
        max-width: 50rem !important;

        @include lg {
            top: 15em;
        }

        @include md {
            top: 10em;
        }

        @include xs {
            top: 12em;
            left: 50%;
            right: unset;
            width: 90% !important;
            transform: translate(-50%, -50%);
        }

        .headline {
            color: $white;
            margin: 0;
            font-size: 3.5rem;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

            @include lg {
                font-size: 3rem;
            }

            @include md {
                font-size: 2.5rem;
            }

            @include xs {
                font-size: 2rem;
            }

            @include xxs {
                font-size: 1.5rem;
            }
        }

        .subhead {
            color: $white;
            font-size: 1.25rem;
            font-weight: 400;
            margin-top: 1em;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

            @include lg {
                margin-top: 0.5em;
            }

            @include md {
                font-size: 1.125rem;
            }

            @include xs {
                font-size: 1rem;
            }
        }

        .button {
            margin-top: 1em;

            @include xs {
                margin: 1em auto;
            }
        }
    }

    .slick-prev {
        left: 32px;
    }

    .slick-next {
        right: 32px;
    }

    .slick-next,
    .slick-prev {
        z-index: 2;
        float: none;
        padding: 0;
        margin: 0;
        background: none;
        color: transparent;
    }

    .slick-prev:before {
        content: "";
        background-image: url("/img/arrow-prev.svg");
        background-size: 30px 30px;
        display: inline-block;
        width: 30px;
        height: 30px;
        z-index: 2;
    }

    .slick-next:before {
        content: "";
        background-image: url("/img/arrow-next.svg");
        background-size: 30px 30px;
        display: inline-block;
        width: 30px;
        height: 30px;
        z-index: 2;
    }

    .slick-dots {
        width: 100%;
        margin: 0 auto;
        display: inline-block !important;
        justify-content: center;
        left: 4em;
        bottom: 4em;

        @include xl {
            left: 17em;
        }

        @media (min-width: 2500px) {
            left: 38.5em;
        }

        @include xs {
            right: 0;
            left: unset;
            transform: none;
            min-width: 100% !important;
        }

        li {
            display: inline-block;
            margin-left: 0.25rem;
            height: auto;
            width: auto;
            border: 1px solid transparent;
        }

        button {
            background-color: rgba(255, 255, 255, 0.7);
            border-radius: 0;
            margin: 0rem;
            background-size: 80%;
            background-repeat: no-repeat;
            background-position: center;
            text-indent: -9999px;
            overflow: hidden;
            opacity: 0.9;
            width: 0.4rem;
            height: 0.4rem;
            border-radius: none;

            &:hover {
                background-color: lighten($primary, 20%);
            }
        }

        .slick-active {
            // margin: 0;
            height: auto;
            width: auto;
            opacity: 1;

            button {
                background-color: $white;
            }
        }
    }
}

#share-price-section {
    position: relative;
    width: 100%;

    .share-price.home {
        position: absolute;
        right: 40px;
        bottom: -5em;
        width: 500px;
        height: 150px;
        background-image: url("/img/share-price-bg.svg");
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 3;
        color: $white;

        @include md {
            right: 0;
            width: 400px;
        }

        @include xs {
            bottom: -6.5em;
            width: 90%;
            height: 135px;
        }

        @media (max-width: 350px) {
            width: 100%;
        }

        .share-price-contents {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-left: 4em;
            height: 92%;

            @include md {
                height: auto;
                padding: 1.5em 1.5em 1.5em 4em;
            }

            @media (max-width: 400px) {
                padding: 1em 1em 1em 4em;
            }

            @media (max-width: 350px) {
                padding: 0.75em 0.75em 0.85em 4em;
            }

            .share-price-text {
                .font-lg.aov {
                    margin-right: 0.25em;
                }
            }

            .share-price-details a {
                color: $white;
            }
        }
    }
}

#home-news-docs {
    padding: 8em 0 3em;
    background: $background;

    @include md {
        padding: 5em 0 2em;
    }

    @include xs {
        padding: 7em 0 2em;
        margin-top: -7em;
    }

    #two-columns-grid {
        .column.left {
            @include xs {
                padding-top: 8em;
            }
        }
    }
}

.latest-news-block {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: 0.3s;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include md {
        height: 400px;
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 36.8%,
            #000000 100%
        );

        @include md {
            background: $black;
            opacity: 0.75;
        }
    }

    &:hover {
        transform: scale(0.975);

        &:before {
            opacity: 0.8;
            background: $secondary;
        }
    }

    .latest-news-content {
        margin: 2em;
        position: absolute;
        color: $white;
        text-align: left;

        @include lg {
            margin: 1.25em;
        }

        span.small-text {
            display: block;
            font-size: 0.875rem;
            font-weight: 200;
            margin-bottom: 0.5em;
            text-transform: uppercase;

            @include xs {
                font-size: 0.75rem;
            }
        }

        h3 {
            display: block;
            font-size: 2rem;
            margin-bottom: 0.5em;

            @include md {
                font-size: 1.75rem;
            }

            @include xs {
                font-size: 1.5rem;
            }
        }
    }
}

.key-docs-block {
    position: relative;
    padding: 2em;
    background: $background;

    @include lg {
        padding: 1em;
    }

    @include xs {
        padding: 0;
    }

    .key-docs-title {
        display: block;
        text-align: left;
        font-size: 1.75rem;
        font-weight: 600;
        color: $secondary;
        width: 100%;
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
        border-bottom: 3px solid #cccccc;

        @include xs {
            font-size: 1.5rem;
        }
    }

    ul.file-icons {
        padding-left: 0;
        margin-top: 1.5em;

        li {
            width: 100%;
            padding: 0 1em 1em 0;
            margin: 1em 0;
            position: relative;
            list-style-type: none;
            border-radius: 4px;
            border: 1px solid $primary;
            background: $white;

            &:hover {
                background: $primary;
                border: 1px solid $tertiary;

                a {
                    color: $white;
                }
            }

            @include xs {
                padding: 0 0.75em 0.75em 0;
            }

            a {
                color: $primary;
                text-decoration: none;
            }

            span {
                padding-top: 1em;
                display: block;
                text-align: right;
                margin-left: 55px;

                @include lg {
                    font-size: 0.95rem;
                }

                @include xs {
                    margin-left: 50px;
                    font-size: 0.875rem;
                    padding-top: 0.75em;
                }
            }

            &::before {
                content: "";
                position: absolute;
                display: block;
                width: 60px;
                height: 100%;
                padding: 1em;
                background-color: $primary;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 24.26px 28px;
                background-image: url("/img/file-pdf.svg");

                @include xs {
                    width: 45px;
                    background-size: 20px 20px;
                }
            }
        }
    }
}

#home-about-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 2em;
    margin-bottom: 3em;
    width: 100%;
    height: 725px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("/img/home-about-bg.png");

    @include lg {
        height: 100%;
        margin-bottom: 2em;
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.15);
    }

    .home-about-text {
        text-align: left;
        width: 60%;
        color: $white;
        z-index: 2;

        @include lg {
            width: 70%;
        }

        @include md {
            width: 100%;
        }

        h3 {
            display: block;
            font-size: 2rem;
            margin-bottom: 1em;

            @include md {
                font-size: 1.75rem;
                margin-bottom: 0.5em;
            }

            @include xs {
                font-size: 1.5rem;
            }
        }

        p {
            font-size: 1rem;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 1em;

            @include md {
                margin-bottom: 0.5em;
            }
        }

        .three-columns-grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1em;
            margin: 2em auto;

            @include md {
                margin: 1.75em auto 0;
            }

            @include xs {
                grid-template-columns: repeat(1, 1fr);
            }

            .item-block {
                display: flex;
                flex-direction: column;
                position: relative;
                padding: 1.5em;
                background: $background;

                h2.section-cms-title {
                    display: block;
                    text-align: left;
                    font-size: 1.25rem;
                    font-weight: 600;
                    color: $primary;
                    width: 100%;
                    padding-bottom: 0.5em;
                    margin: 0 0 0.5em;
                    border-bottom: 3px solid #cccccc;
                }

                p {
                    display: block;
                    font-weight: 300;
                    font-size: 0.875rem;
                    line-height: 24px;
                    margin-bottom: 0.75em;

                    @include xs {
                        margin-bottom: 0;
                    }
                }

                a.button {
                    font-weight: 600;
                    font-size: 0.875rem;
                    text-decoration: none;
                    padding: 0 0 0.25rem;
                    position: relative;
                    color: $primary;
                    border: none;
                    border-radius: unset;

                    &:after {
                        content: "";
                        display: inline-block;
                        margin-left: 0.5em;
                        width: 11.82px;
                        height: 10.38px;
                        z-index: 2;
                        background-size: 11.82px 10.38px;
                        background-image: url("/img/double-arrows.svg");
                    }

                    &:hover {
                        border-bottom: 1px solid $primary;
                    }

                    @include xs {
                        padding-top: 0.5em;
                    }
                }
            }
        }
    }
}

#home-stats-section {
    width: 100%;
    padding: 2em 1em;
    margin-bottom: 3em;
    background: $secondary;

    .home-stats-title {
        display: block;
        text-align: center;
        font-size: 1.75rem;
        font-weight: 600;
        color: $white;
        text-transform: uppercase;
        width: 100%;

        @include xs {
            font-size: 1.5rem;
        }
    }

    .g-stat-3-col {
        display: flex;
        justify-content: center;
        margin: 2em auto;

        @media (max-width: 400px) {
            flex-direction: column;
            align-items: center;
            margin: 1em auto;
        }

        .col {
            text-align: center;
            padding: 0 4em;
            border-right: 1px solid $white;

            &:last-child {
                border: none;
            }

            @include md {
                padding: 0 2em;
            }

            @include xs {
                padding: 0 1.5em;
            }

            @media (max-width: 400px) {
                width: fit-content;
                border-right: none;
                padding: 1em 0;
                border-bottom: 1px solid $white;
            }

            .stat-number {
                color: $white;
                font-size: 2.85rem;
                font-weight: 600;

                @include xs {
                    font-size: 2.25rem;
                }
            }

            .stat-text {
                color: $white;
                font-size: 1rem;
                margin: 0.25em 0 0 0;
            }
        }
    }
}

#home #panels {
    margin: 1em auto;

    > div {
        text-align: left;
        background: $secondary;

        h2.section-cms-title {
            display: block;
            font-size: 1.35rem;
            font-weight: 600;
            text-transform: uppercase;
            color: $white;
            margin: 0 0 0.75em;
            padding: 0;
            border: none;
        }

        p {
            display: block;
            color: $white;
            font-weight: 300;
            line-height: 24px;
            margin-bottom: 0.75em;
        }

        a.button {
            font-weight: 600;
            font-size: 1rem;
            text-decoration: none;
            padding: 0 0 0.25rem;
            position: relative;
            color: $white;
            border: none;
            border-radius: unset;

            &:after {
                content: "";
                display: inline-block;
                margin-left: 0.5em;
                width: 11.82px;
                height: 10.38px;
                z-index: 2;
                background-size: 11.82px 10.38px;
                background-image: url("/img/double-arrows-white.svg");
            }

            &:hover {
                border-bottom: 1px solid $white;
            }
        }
    }
}

#home-policy-section {
    padding: 0;
    background: $background;

    @include xs {
        padding: 0;
    }

    .container {
        padding-top: 2em;
        padding-bottom: 2em;

        @include xs {
            width: 100%;
        }
    }

    .row {
        padding: 1em;
        border: 1px solid $primary;

        span {
            color: $primary;
            font-size: 1.5rem;
            font-weight: 600;

            @include xs {
                font-size: 1.25rem;
                text-align: left;
            }
        }

        .column.right {
            text-align: left;

            @include md {
                margin-top: 1em;
            }

            @include xs {
                margin-top: 0.5em;
            }

            p {
                color: $primary;
                margin: 0.5em 0 0 0;
            }
        }
    }
}

// -- Investor Centre

#dates-news {
    display: block;
    height: 100%;
    margin-bottom: 2em;

    .row {
        align-items: flex-start;
    }

    #key-dates {
        width: 100%;
        height: 100%;
        padding: 2em;
        background: $background;
        margin-right: 2em;

        @include xs {
            margin-right: 0;
        }

        .key-dates-title {
            display: block;
            text-align: left;
            font-size: 1.75rem;
            font-weight: 600;
            color: $primary;
            width: 100%;
            padding-bottom: 0.5em;
            margin-bottom: 0.5em;
            border-bottom: 3px solid #cccccc;

            @include xs {
                font-size: 1.5rem;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: flex;
                background: $white;
                padding: 1.5rem;
                box-shadow: 0 4px 5px 0 rgba($black, 0.15);
                align-items: center;
                font-weight: 300;
                color: $primary;

                @include xs {
                    padding: 1rem;
                }

                + li {
                    margin-top: 1.25rem;
                }

                .date {
                    display: flex;
                    width: 3rem;
                    margin: 0 2.75rem 0 0;
                    flex-direction: column;
                    align-items: center;
                    font-weight: 400;
                    line-height: 1;
                    color: $primary;

                    @include xs {
                        margin: 0 1.5rem 0 0;
                    }

                    .day {
                        margin-bottom: 0.125rem;
                        font-size: 220%;

                        @include xs {
                            font-size: 185%;
                        }
                    }

                    .month {
                        margin-bottom: 0.375rem;
                        font-size: 135%;

                        @include xs {
                            font-size: 115%;
                        }
                    }

                    .year {
                        font-size: 110%;

                        @include xs {
                            font-size: 95%;
                        }
                    }
                }

                .description {
                    > *:last-child {
                        margin-bottom: 0;
                    }

                    p {
                        margin-bottom: 0.5rem;
                    }
                }
            }
        }
    }
}

#news-list {
    background: darken($white, 2.5%);
    width: 100%;
    padding: 2em;

    .key-news-title {
        display: block;
        text-align: left;
        font-size: 1.75rem;
        font-weight: 600;
        color: $primary;
        width: 100%;
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
        border-bottom: 3px solid #cccccc;

        @include xs {
            font-size: 1.5rem;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: flex;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $secondary;

            + li {
                margin-top: 1rem;
            }

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }

            .date {
                display: flex;
                width: 3rem;
                margin: 0 1.875rem 0 0;
                flex-direction: column;
                align-items: center;
                font-weight: 400;
                line-height: 1;
                color: $secondary;

                .day {
                    margin-bottom: 0.125rem;
                    font-size: 220%;

                    @include xs {
                        font-size: 185%;
                    }
                }

                .month {
                    margin-bottom: 0.375rem;
                    font-size: 135%;

                    @include xs {
                        font-size: 115%;
                    }
                }

                .year {
                    font-size: 110%;

                    @include xs {
                        font-size: 95%;
                    }
                }
            }

            .description {
                font-weight: 300;
                color: $secondary;

                > *:last-child {
                    margin-bottom: 0;
                }

                h3 {
                    font-size: 1.25rem;
                    line-height: 28px;
                    margin: 0;
                }

                p {
                    font-size: 1rem;
                }
            }
        }
    }
}

// -- Share Price

.share-price {
    text-align: left;
    color: $share-price-grey;

    .share-price-text {
        text-align: center;

        .font-lg.aov {
            margin-right: 0.25em;
        }

        small {
            font-size: 1.125rem;
            padding-left: 0.5em;

            @include md {
                font-size: 0.875rem;
                padding-left: 0.25em;
            }

            @include xs {
                font-size: 0.75rem;
                padding-left: 0.25em;
            }
        }
    }

    span.font-lg {
        font-size: 3rem;
        font-weight: 600;

        @include md {
            font-size: 2.25rem;
        }

        @include xs {
            font-size: 2rem;
        }

        @media (max-width: 350px) {
            font-size: 1.75rem;
        }
    }

    .share-price-details {
        display: flex;
        justify-content: space-between;
        margin-top: 0.25em;

        @media (max-width: 400px) {
            flex-wrap: wrap;
            justify-content: center;
            gap: 0.25em;
        }

        span {
            font-size: 0.75rem;
            font-weight: 400;
            padding: 0 0.75em;
            border-right: 1px solid $share-price-grey;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                border-right: none;
                padding-right: 0;
            }

            @include md {
                font-size: 0.65rem;
                padding: 0 0.5em;
            }

            @media (max-width: 400px) {
                font-size: 0.65rem;
                padding: 0 0.5em;
                white-space: nowrap;
            }
        }

        a {
            color: $share-price-grey;

            &:hover {
                color: $white;
            }
        }
    }
}

// -- Logo Slider

.logo-slider {
    margin: 2em auto;

    .slick-list {
        width: 100%;
    }

    .slick-track {
        display: flex;
        margin: 0;
        width: 100%;
    }

    .slick-slide {
        width: auto;
        height: 70px;
        margin: 0 1em;

        &:before {
            background-color: transparent !important;
            opacity: 1 !important;
        }

        .slide-image {
            opacity: 1;
            height: 70px;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

// -- CMS Page

.main-slider {
    height: 50vw;

    @include xs {
        height: 65vw;
    }

    &.slick-dotted.slick-slider {
        margin-bottom: 0;
    }

    .slick-slide {
        height: 50vw;

        @include xs {
            height: 65vw;
        }
    }

    .slick-dots {
        width: 100%;
        margin: 0 auto;
        display: inline-block !important;
        justify-content: center;
        left: 4em;
        bottom: 2em;

        @include xs {
            bottom: 1em;
            left: 2em;
        }

        li {
            display: inline-block;
            margin-left: 0.25rem;
            height: auto;
            width: auto;
            border: 1px solid transparent;
        }

        button {
            background-color: rgba(255, 255, 255, 0.7);
            border-radius: 0;
            margin: 0rem;
            background-size: 80%;
            background-repeat: no-repeat;
            background-position: center;
            text-indent: -9999px;
            overflow: hidden;
            opacity: 0.9;
            width: 0.4rem;
            height: 0.4rem;
            border-radius: none;

            &:hover {
                background-color: lighten($primary, 20%);
            }
        }

        .slick-active {
            // margin: 0;
            height: auto;
            width: auto;
            opacity: 1;

            button {
                background-color: $white;
            }
        }
    }

    figure {
        position: relative;
        height: 100%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
    }

    .container {
        position: relative;
        height: 100%;
        width: 100%;
        min-width: 100%;
    }

    .caption-container {
        position: relative;
        max-width: 1440px;
        height: 100%;
        margin: 0 auto;
        display: block;
        z-index: 999;

        @include xs {
            width: 100%;
            height: 50%;
            top: 50vw;
            position: relative;
            max-width: 100%;
        }

        @include xxs {
            top: 55vw;
        }
    }

    .caption {
        position: absolute;
        background: transparent !important;
        color: $primary;
        text-align: left;
        z-index: 999;
        padding: 0 !important;
        bottom: 4em;
        left: 3em;
        width: 90%;

        @include xs {
            left: 1.5em;
        }

        .headline {
            color: $white;
            margin: 0;
            font-size: 2rem;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

            @include lg {
                font-size: 3rem;
            }

            @include md {
                font-size: 2.5rem;
            }

            @include xs {
                font-size: 2rem;
            }

            @include xxs {
                font-size: 1.5rem;
            }
        }

        .subhead {
            color: $white;
            font-size: 1.125rem;
            font-weight: 400;
            margin-top: 0.5em;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);

            @include lg {
                margin-top: 0.5em;
            }

            @include md {
                font-size: 1.125rem;
            }

            @include xs {
                font-size: 1rem;
            }
        }

        .button {
            margin-top: 1em;

            @include xs {
                margin: 1em auto;
            }
        }
    }
}

#page-header {
    position: relative;
    width: 100%;
    height: 300px;
    padding: 2em 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include xs {
        height: 100%;
    }

    &.blog-page {
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(12, 32, 54, 0.75);
        }

        small {
            color: $white;
        }
    }

    .container {
        height: 100%;
    }

    .page-header-text {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 75%;
        max-width: 60rem;
        height: 100%;

        @include xs {
            width: 100%;
        }

        h1 {
            position: relative;
            color: $white;
            margin: 0;
            padding-bottom: 0.75em;
            margin-bottom: 0.5em;

            &:after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                background: #ccc;
                width: 100%;
                height: 5px;
            }

            @include xs {
                font-size: 1.75rem;
            }
        }

        h2 {
            color: $white;
            margin: 0;
        }

        p {
            color: $white;
            margin-bottom: 0;
        }
    }
}

#cms-page-contents {
    margin: 2em auto;

    @include xs {
        margin: 0;
    }
}

.content-block {
    margin: 2em auto;

    &.cols-2 {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;

        @include lg {
            padding: 0;
        }

        div {
            flex-basis: 50%;
            width: 100%;
            padding: 0 3em 0 0;

            &:last-child {
                padding: 0;

                @include xs {
                    padding-top: 1em;
                }
            }

            @include lg {
                flex-basis: 100%;
                width: 100%;
                padding: 0;
            }

            img {
                width: 100%;
                max-height: 400px;
                margin: 0;

                @include md {
                    margin-top: 1em;
                }
            }
        }
    }

    &.cols-3 {
        display: flex;
        flex-wrap: wrap;

        div {
            flex-basis: 33.3333333%;
            width: 100%;
            padding: 0 2em 0 0;

            &:last-child {
                padding: 0;
            }

            @include lg {
                flex-basis: 50%;
                width: 100%;
                padding: 0 3em 0 0;
            }

            @include xs {
                flex-basis: 100%;
                padding: 0;
            }

            img {
                max-height: 400px;
            }
        }
    }
}

.content-block {
    .text {
        text-align: left;

        h2.section-cms-title {
            color: $primary;
            line-height: 32px;
            margin: 0 0 1em;
            padding-bottom: 1em;
            border-bottom: 3px solid #cccccc;

            @include xs {
                font-size: 1.5rem;
                margin: 0 0 0.5em;
                padding-bottom: 0.5em;
            }
        }

        a {
            color: $primary;
        }

        a.button {
            padding: 0.5rem 1.25rem;
        }

        img {
            height: 100%;
            object-fit: cover;

            @include xs {
                height: auto !important;
                width: 100% !important;
            }
        }

        iframe {
            width: 100% !important;

            @include xs {
                width: 100%;
                min-height: 250px;
            }
        }
    }
}

table {
    border: 0 none;
    border-collapse: collapse;

    &.fr-alternate-rows {
        tbody {
            tr:nth-child(2n) {
                background: rgba($black, 0.03);
            }
        }
    }

    @include lg {
        max-width: 100%;
        overflow-y: scroll;
        display: block;
        overflow-x: auto;
        white-space: nowrap;
    }

    .fr-highlighted {
        background: rgba($primary, 0.15);
    }

    tr {
        th {
            background: $primary;
            padding: 0.75rem;
            border: 1px solid $primary;
            border-right-color: $white;
            color: $white;

            &:last-child {
                border-right-color: $primary;
            }
        }

        td {
            padding: 0.75rem;
            border-top: 1px solid $light-grey;

            a {
                color: $primary;
            }
        }
    }
}
.resp-table {
    tr {
        @include xs {
            display: flex;
            flex-wrap: wrap;

            + tr {
                margin-top: 2rem;
                padding-top: 1.875rem;
                border-top: 1px solid $light-grey;
            }

            &:hover {
                background: none;
            }
        }
    }

    td {
        padding: 0.75rem;
        border-top: 1px solid $light-grey;

        @include xs {
            width: 100% !important;
        }
    }
}

.centered-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

#loose-section-title {
    margin: 2em auto 0;
}

#panels {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin: 2em auto;

    @include xs {
        margin: 1em auto;
        grid-template-columns: repeat(1, 1fr);
    }

    > div {
        position: relative;
        padding: 2em;
        text-align: left;
        background: $background;

        @include xs {
            padding: 1.5em;
        }

        h2 {
            display: block;
            width: 100%;
            font-size: 1.35rem;
            font-weight: 600;
            color: $primary;
            line-height: 27px;
            margin: 0 0.75em 0 0;
            padding-bottom: 0.5em;
            margin-bottom: 0.5em;
            border-bottom: 3px solid #cccccc;
        }

        p {
            display: block;
            font-weight: 300;
            line-height: 24px;

            @include xs {
                margin-bottom: 0;
            }
        }

        a.button {
            font-weight: 600;
            font-size: 1rem;
            text-decoration: none;
            padding: 0 0 0.25rem;
            position: relative;
            color: $primary;
            border: none;
            border-radius: unset;

            &:after {
                content: "";
                display: inline-block;
                margin-left: 0.5em;
                width: 11.82px;
                height: 10.38px;
                z-index: 2;
                background-size: 11.82px 10.38px;
                background-image: url("/img/double-arrows.svg");
            }

            &:hover {
                border-bottom: 1px solid $primary;
            }

            @include xs {
                padding-top: 0.75rem;
            }
        }
    }
}

// Documents

.documents {
    width: 100%;

    thead {
        @include xs {
            display: none;
        }

        tr {
            th {
                text-align: left;
            }
        }
    }

    tr {
        &:hover {
            background: rgba($primary, 0.05);
        }

        @include xs {
            display: flex;
            flex-wrap: wrap;

            + tr {
                margin-top: 2rem;
                padding-top: 1.875rem;
                border-top: 1px solid $light-grey;
            }

            &:hover {
                background: none;
            }
        }
    }

    td {
        @include xs {
            width: 100%;
            border-top: 0 none;
        }

        &.date {
            width: 7.5rem;
            white-space: nowrap;
            text-align: center;

            @include xs {
                width: 100%;
                padding: 0 0 0.25rem;
                text-align: left;
                font-size: 80%;
            }
        }
    }

    .title {
        @include xs {
            padding: 0;
            font-weight: 500;
        }
    }

    .download {
        width: 8.125rem;
        text-align: center;

        @include xs {
            width: 100%;
            padding: 0.75rem 0 0;
            text-align: left;
        }

        .button {
            padding: 0.5rem 1rem;
            display: inline-block;
            font-size: 0.75rem;
            color: $primary;

            &:hover {
                color: $white;
                background: $primary;
            }

            @include xs {
                padding: 0.5rem 0.75rem;
            }
        }
    }
}

.tablesorter-header {
    position: relative;

    &:not(.sorter-false) {
        cursor: pointer;
    }

    &.tablesorter-headerDesc {
        &:after {
            border-top: 5px solid $white;
            border-bottom: 0 none;
        }
    }

    &.tablesorter-headerAsc {
        &:after {
            border-top: 0 none;
            border-bottom: 5px solid $white;
        }
    }

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 0;
        height: 0;
        margin: 0 0.75rem;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        transform: translate(0, -50%);
    }
}

// Tabs

#investor-tabs {
    height: 600px;

    @include xs {
        height: 100%;
        margin-bottom: 2em;
    }
}

.tabs {
    max-width: calc(1280px + 3.75rem);
    margin: 0 auto;

    @include xs {
        padding: 0;
    }

    dt {
        float: left;
        position: relative;
        z-index: 0;

        @include xs {
            float: none;
            margin: 0;
        }

        &:hover {
            z-index: 1;
        }

        &:first-of-type,
        &:last-of-type {
            margin: 0;
        }

        &.active {
            z-index: 3;

            & + dd {
                display: block;
            }

            a {
                background: $white;
                color: $primary;

                @include xs {
                    background: $primary;
                    border-bottom: 0 none;
                    color: $white;
                }
            }
        }

        a {
            display: inline-block;
            background: darken($white, 2%);
            margin: 0;
            padding: 0.75rem 1.375rem;
            border: 2px solid;
            border-bottom-color: transparent;
            border-top-right-radius: 0.375rem;
            border-top-left-radius: 0.375rem;
            text-decoration: none;
            font-weight: 600;
            font-size: 110%;
            color: darken($light-grey, 10%);

            @include xs {
                width: 100%;
                border-radius: 3px;
                border: 1px solid;

                background: $primary;
                border-bottom: 0 none;
                color: $white;
            }
        }
    }

    dd {
        float: right;
        display: none;
        position: relative;
        width: 100%;
        margin: 3.125rem 0 0 -100%;
        padding: 2rem 1rem 0;
        border-top: 2px solid $primary;
        z-index: 2;

        @include xs {
            float: none;
            margin: 0;
            padding: 0.5rem;
            border-top: 0 none;
        }

        a {
            color: $primary;
        }
    }
}

// Galleries

.gallery {
    .gallery-header {
        text-align: left;
        margin-bottom: 2em;

        h2 {
            display: block;
            text-align: left;
            font-size: 1.75rem;
            font-weight: 600;
            color: $primary;
            width: 100%;
            padding-bottom: 0.5em;
            margin-bottom: 0.5em;
            border-bottom: 3px solid #cccccc;

            @include xs {
                font-size: 1.5rem;
            }
        }
    }

    .images {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2em;

        @include lg {
            display: flex;
            flex-wrap: wrap;
            gap: 2em;
        }

        @include xs {
            display: block;
        }

        figure {
            display: block;
            width: 100%;
            max-width: 400px;

            @include lg {
                width: calc((50% - 1rem));
            }

            @include xs {
                width: calc(100% - 2.5rem);
                margin-bottom: 1em;
            }

            .gallery-link {
                display: block;
                text-decoration: none;
            }

            figcaption {
                background: $primary;
                padding: 1rem;
                color: $white;

                :last-child {
                    margin-bottom: 0;
                }

                h4 {
                    font-size: 1rem;
                    color: $white;
                    margin: 0 0 0.5em;
                    word-wrap: break-word;
                }
            }

            img {
                display: block;
                max-width: 100%;
            }
        }
    }
}

// -- Webcasts

#webcasts .gallery {
    .gallery-header {
        text-align: left;
        margin-bottom: 2em;

        h2 {
            display: block;
            text-align: left;
            font-size: 1.75rem;
            font-weight: 600;
            color: $primary;
            width: 100%;
            padding-bottom: 0.5em;
            margin-bottom: 0.5em;
            border-bottom: 3px solid #cccccc;

            @include xs {
                font-size: 1.5rem;
            }
        }
    }

    .images {
        display: flex;
        flex-wrap: wrap;
        background: red;

        figure {
            width: calc((100% - (#{$node-spacing} * 4)) / 3);
            margin: 0 0 $node-spacing $node-spacing;

            .gallery-link {
                display: block;
                text-decoration: none;
                font-weight: normal;
            }

            figcaption {
                background: $primary;
                padding: 1rem;
                color: $white;

                :last-child {
                    margin-bottom: 0;
                }

                h4 {
                    color: $primary;
                    margin: 0 0 0.5em;
                }
            }

            img {
                display: block;
                max-width: 100%;
            }
        }
    }
}

// -- Board

// People

.people {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
    margin: 2em 0;
    padding: 0;
    list-style: none;

    @include xs {
        grid-template-columns: repeat(1, 1fr);
        gap: 1em;
    }

    li {
        padding: 1em;
        background: $background;

        .row-top {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 1em;
            margin-bottom: 1em;
            border-bottom: 3px solid #cccccc;
        }

        img {
            max-width: 9.75rem;
            padding-left: 0.375rem;
            z-index: 1;

            @include md {
                max-width: 6.75rem;
            }
        }

        .nameplate {
            position: relative;
            margin-left: 2em;
            z-index: 0;

            @include lg {
                margin-left: 1em;
            }

            h3 {
                margin: 0 0 0.125rem;
                font-size: 110%;

                &.nonexec {
                    &:after {
                        content: "*";
                        position: relative;
                        top: -6px;
                        margin-left: 0.25rem;
                        font-size: 75%;
                    }
                }
            }

            .qualifications {
                margin: 0;
                font-weight: 300;
                color: $primary;
            }

            .title {
                margin: 0;
                font-style: italic;
                color: $primary;
            }
        }

        .description {
            .title {
                color: $primary;
            }
        }
    }
}

// -- Contact

#contact {
    .gallery {
        .gallery-header {
            display: none;
        }

        .images {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 2em;

            @include md {
                grid-template-columns: repeat(2, 1fr);
            }

            @include xs {
                grid-template-columns: repeat(1, 1fr);
            }

            figure {
                width: 100%;
                margin: 0;

                img {
                    max-height: 3rem;
                    margin-bottom: 1rem;
                }

                figcaption {
                    background: transparent;
                    padding: 0;
                    color: $black;

                    h4 {
                        display: none;
                    }
                }
            }
        }
    }
}

// -- News

#blog-index {
    margin: 2em auto;
}

.blog-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1em;
    margin-bottom: 2em;

    @include lg {
        grid-template-columns: repeat(3, 1fr);
    }

    @include md {
        grid-template-columns: repeat(2, 1fr);
    }

    @include xs {
        grid-template-columns: repeat(1, 1fr);
    }

    .blog-item {
        width: 100%;
        text-decoration: none;
        border: 1px solid $background;
        background-color: $background;
        color: #303133;
        transition: 0.3s;
        text-align: left;
        padding: 0;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            border: 1px solid $primary;
        }

        .blog-item-img {
            display: block;
            width: 100%;
            height: 13em;
            background-color: $secondary;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

.blog-item-brief {
    padding: 1em;

    h4 {
        color: $primary;
        font-size: 1.25rem;
        font-weight: 600;
        margin: 0 0 0.5em;
    }

    p {
        margin: 0.5em 0;
    }

    .read-more-button {
        display: block;
        margin-top: 1em;
        font-weight: 600;
        color: $primary;
    }
}

.breadcrumb {
    margin: 1em 0;

    .back {
        font-size: 1rem;
        list-style-type: none;

        &:before {
            content: "";
            position: relative;
            right: 0;
            display: inline-block;
            width: 14px;
            height: 14px;
            z-index: 2;
            margin-right: 0.75em;
            vertical-align: middle;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url("/img/arrow-green.svg");
            transform: rotate(180deg);
        }

        a {
            color: $primary;
            text-decoration: none;
        }
    }
}

.blog-intro {
    margin-bottom: 1em;

    .blog-title {
        h1 {
            margin-bottom: 0.25em;
        }
    }
}

.blog-image {
    width: 100%;
    margin: 1em 0;

    img {
        display: block;
        max-height: 35vh;
        object-fit: contain;
        object-position: left;
    }
}

.blog-content {
    margin: 2em auto;

    img {
        @include xs {
            width: 100% !important;
        }
    }
}

.blog-tags-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.5em 0;
    border-top: 1px solid $secondary;

    span {
        font-size: 1rem;
        margin-right: 1em;
        font-weight: 600;
    }

    .blog-tags {
        padding-left: 0;
        display: flex;
        margin: 0.75em 0;

        li {
            border: 1px solid $secondary;
            padding: 0.5em 1em;
            margin-left: 1em;
            margin-bottom: 0;
            list-style-type: none;

            &:first-child {
                margin-left: 0;
            }

            &:hover {
                color: $white;
                border: none;
                background: $primary;
            }

            a {
                font-size: 0.875rem;
                text-decoration: none;
            }
        }
    }
}

// -- Pagination

.pagination {
    margin: 3rem 0;
    display: flex;
    width: 100%;
    justify-content: center;
    list-style: none;
    padding-left: 0;

    @include md {
        margin: 2rem auto;
        justify-content: center;
    }

    .page-item {
        height: 100%;
        padding: 5px 10px;
        text-align: center;
        margin: 0 0.25em;
        line-height: normal;

        @include xs {
            padding: 2px;
        }
        @include xxs {
            padding: 0;
        }
        a {
            display: block;
            height: 100%;
            color: $secondary;
            font-weight: 600;
            text-decoration: none;
        }
        &:hover {
            color: $secondary;
            text-decoration: none;
        }
        &:first-of-type {
            border-left: 0;
        }
        &.active {
            span {
                color: $primary;
                font-weight: 600;
            }
        }
        &.disabled {
            background: transparent;
            color: $secondary;
        }
        &.arrow {
            background: none;
            a {
                color: $secondary;
            }
        }
    }
}
