// Colours
$white: #ffffff;
$black: #000000;
$light-grey: #dfdfdf;
$dark-grey: #212121;

$share-price-grey: #bab5b5;

$primary: #0f6fc6;
$secondary: #0c2036;
$tertiary: #009dd9;

$background: #ecf0f3;

$positive: #0ab04b;
$negative: #e64747;

$label: #1c4c56;
$input-bg: #ffffff;
$input-border: #dfdfdf;
$input-font: #212121;

// Spacing
$btn-padding: 1rem 1.5rem;
$node-spacing: 1.875rem;

// Typography
$font: "Noto Sans", sans-serif;
